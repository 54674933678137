import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider, addLocaleData } from 'react-intl'
import '../styles/index.scss'

// Locale data
import { localeData } from '../i18n/locales'
import en from '../i18n/messages/en.js'
import sv from '../i18n/messages/sv.js'
import { Location } from '@reach/router'

// Components
import Header from './header'
import Footer from './footer'

addLocaleData(localeData)

const messages = { en, sv }

const Layout = ({ locale, children }) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Location>
        {({ location }) => {
          return (
            <div className="wrapper">
              <Header locale={locale} location={location} />
              <div>{children}</div>
              <Footer />
            </div>
          )
        }}
      </Location>
    </IntlProvider>
  )
}

Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
