import React, { Component } from 'react'
import { languages } from '../i18n/locales'
import * as styles from '../styles/Langswitch.module.scss'
import { withPrefix } from 'gatsby'
import '../../node_modules/flag-icon-css/sass/flag-icon.scss'

class Language extends Component {
  constructor(props) {
    super(props)
    this.state = {
      languages: languages,
      locale: '',
      showMenu: false,
    }
  }

  showMenu = e => {
    e.preventDefault()
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  closeMenu = e => {
    if (!this.dropDownMenu.contains(e.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu)
      })
    }
  }

  componentDidMount() {
    const locale = this.props.locale
    this.setState({
      locale: locale || locale.detected,
    })
  }

  handleChange = e => {
    const value = e.target.dataset.val
    const { locale } = this.state
    if (value === locale) {
      return
    }
    this.setState({ locale: value }, () => {
      localStorage.setItem('language', value)
      const url = this.props.location.pathname
      const originalPath = url.substring(3)
      const redirectURl = withPrefix(`/${value}${originalPath}`)
      window.location.href = redirectURl
    })
  }

  render() {
    const { locale, languages } = this.state
    let showLang
    languages.forEach(lang => {
      if (locale === lang.value) {
        return (showLang = lang.text)
      }
    })

    if (!locale) {
      return null
    }
    let flag
    if (locale === 'en') {
      flag = 'gb'
    } else if (locale === 'sv') {
      flag = 'se'
    }
    return (
      <div>
        <div>
          <div
            className={styles.lang}
            style={{ outline: 'none' }}
            data-val={locale}
            role="button"
            onClick={this.showMenu}
            tabIndex="0"
          >
            <span
              className={`flag-icon ${styles.flagsIcon} flag-icon-${flag}`}
            />
            <span className={styles.langName}>{showLang}</span>

            {this.state.showMenu ? (
              <ul
                ref={element => {
                  this.dropDownMenu = element
                }}
                className={styles.popup}
                style={{ outline: 'none' }}
                data-val={locale}
              >
                {languages.map(({ value, text }) => {
                  let flagIcon
                  if (value === 'en') {
                    flagIcon = 'gb'
                  } else if (value === 'sv') {
                    flagIcon = 'se'
                  }
                  return (
                    <li key={value}>
                      <span
                        className={`flag-icon ${styles.flagsIcon} flag-icon-${flagIcon}`}
                      />
                      <span
                        data-val={value}
                        role="button"
                        tabIndex="-1"
                        onClick={this.handleChange}
                      >
                        {text}
                      </span>
                    </li>
                  )
                })}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default Language
