module.exports = {
  title: 'Flow',
  hello: 'Hallå',
  welcome: 'Flow gör det lättare',
  read_more: 'Läs mer',
  search: 'Sök',
  // Menu
  menu_conf: 'Konferens',
  apps: 'Appar',
  menu_cal: 'Kalender',
  sign_in: 'Logga in',
  // Conference Page
  sweden_info: `<p>Vem som helst, oavsett var i världen de befinner sig kan delta i din konferens. För de som ringer internt, inom företaget, är samtalet kostnadsfritt. För övriga deltagare som ringer inom Sverige är kostnaden ett svenskt lokalsamtal till fastnummer.</p>
  <br>
  <p>Från utlandet ringer man <a href='tel:+46465900099'>+46465 90 00 99</a> för att nå konferensen och kostnaden för detta är som till vilket svenskt fastnummer som helst från det land man befinner sig i. För ett stort antal länder erbjuder vi även lokala nummer som man kan nå konferensen från. Detta reducerar kostnaden till ett lokalsamtal inom landet.</p>`,

  goback: 'Gå tillbaka',
  conf: 'Konferensnummer',
  callingfrom: 'Vilket land ringer du ifrån?',
  price:
    'Priset för det här samtalet är detsamma som ett lokalt samtal inom ditt land.',
  how_to_join: 'Hur man går med i telefonkonferensen',
  dial: 'Slå nummer: ',
  code: 'Ange konferenskoden: ',
  mobile_dial: '... eller ring direkt från mobilen: ',
  // Calendar Page
  calendar_headline: 'Kalenderintegration',
  download: 'Ladda ner',
  open_in_browser: 'Öppna i Webbläsaren',
  change_os: 'Ändra Operativsystem',
  calendar_p: `
        <p>
          Med <strong>kalenderintegration</strong> kan du låta din kalender styra vilken profil som skall vara aktiv för din anknytning. När du lägger in ett möte via din dator eller din smartphone kommer din anknytning automatisk ställas om och du riskerar inte att bli störd.
        </p>
        <p>
        För att aktivera en profil vid en kalenderhändelse krävs att du någonstans i kalenderns fält för <strong>anteckningar</strong> eller <strong>ämne</strong> skriver ”<strong>status:[profilnummer]</strong>”. För Lunch skriv: “<strong>status:1</strong>“, för Möte: “<strong>status:2</strong>“, för Slutat för dagen: “<strong>status:3</strong>“ och så vidare.
        </p>
        <p>
          Du kan även automatisera mötesprofilen. Logga in i <a href='http://admin.joinflow.com/' target='_blank' rel='noreferrer noopener'>webbappen</a> <strong>></strong> inställningar <strong>></strong> arbetstider <strong>></strong> extern kalender <strong>></strong> slå på autoaktivera möten. Alla evenemang som har fler deltagare än en kommer nu automatiskt att aktivera profilen Möte.
        </p>
        <p>
          För att vi skall kunna styra din anknytning behöver vi få tillgång till din kalender.
        </p>
        `,
}
