import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <a href="/static/privacy-policy">Privacy policy</a>
        <a href="/static/terms-and-conditions">Terms & conditions</a>
      </div>
    </div>
  )
}

export default Footer
