module.exports = {
  // Frontpage
  title: 'Flow',
  hello: 'Hi people',
  welcome: 'Flow makes it easier',
  read_more: 'Read more',
  search: 'Search',
  // Menu
  menu_conf: 'Conference',
  menu_cal: 'Calendar',
  sign_in: 'Sign in',
  apps: 'Apps',
  // Conference Page
  sweden_info: `<p>Anyone, no matter where they are in the world can call your conference. For those calling internally, within your company, the call is free of charge. For other participants, calling from Sweden, the cost is equal to that of a local call.</p>
  <br>
  <p>From outside of Sweden the conference can be reached at <a href='tel:+46465900099'>+46465 90 00 99</a> and the cost is the same as an ordinary call to Sweden from that country. For many countries we also offer local numbers from wich the conference can be reached. This reduces the cost to that of a local call within the country.</p>`,
  goback: 'Go back',
  conf: 'Conference number',
  callingfrom: 'Which country are you calling from?',
  price:
    'The price for this call is the same as a local call within your country.',
  how_to_join: 'How to join the phone conference',
  dial: 'Dial number: ',
  code: 'Enter the conference code: ',
  mobile_dial: '...or call direct via mobile phone: ',
  // Calendar Page
  calendar_headline: 'Calendar integrations',
  download: 'Download',
  open_in_browser: 'Open in Browser',
  change_os: 'Change OS',
  calendar_p: `
        <p>
          With <strong>calendar integration</strong> you can
          let your calendar determine which profile is active
          for your extension.When you add a meeting via your computer or smartphone, your extension will automatically be adjusted during the meeting so that you are not disturbed.
        </p>
        <p>
          In order to <strong>activate</strong> a profile based on a calendar event, you must enter: "<strong>status:[profile number]</strong>" somewhere calendar 's field for <strong>notes</strong> or <strong>subject</strong>.
          For Lunch write: "<strong>status:1</strong>", Meeting: "<strong>status:2</strong>", Left for the day: "<strong>status:3</strong>" and so on...
        </p>
        <p>
          You can also automate the meeting profile. <strong><a href='https://admin.joinflow.com/' target='_blank' rel='noreferrer noopener'>Sign in</a></strong> to the Web App <strong>></strong> Settings <strong>></strong> Working hours <strong>></strong> External Calendar <strong>></strong> Turn on auto-enable meetings. Any event that has more participants than one will now automatically activate the Meeting Meeting.
        </p>
        <p>
          In order to be able to control your extension, we need to have access to your calendar.
        </p>`,
}
