import React, { Component } from 'react'
import { Link, withPrefix } from 'gatsby'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'

//components
import Language from './language'
import { FormattedMessage } from 'react-intl'

//Styles
import * as styles from '../styles/Header.module.scss'
import logo from '../images/flow_logo.png'

// this link will be active when itself or deeper routes are current
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: styles.active } : null
}

// const Header = ({ locale, location, intl }) =>

class Header extends Component {
  constructor() {
    super()
    this.state = {
      nav: {},
    }
  }

  componentDidMount() {
    const nav = window.navigator
    this.setState({
      nav,
    })
  }
  render() {
    const { platform, userAgent } = this.state.nav
    const { locale, location, intl } = this.props
    const homeLink = `/${locale}/`
    const calendarLink = `/${locale}/calendar`
    const conferenceLink = `/${locale}/conference`

    var appLink = `/${locale}${withPrefix(`/apps/`)}`

    if (platform === undefined) {
      return <p>Loading</p>
    } else {
      var ua = userAgent.toLowerCase()
      if (ua.indexOf('macintosh') !== -1) {
        appLink += 'osx'
      } else if (ua.indexOf('iphone') !== -1) {
        appLink += 'ios'
      } else if (ua.indexOf('android') !== -1) {
        appLink += 'android'
      } else if (ua.indexOf('windows') !== -1) {
        appLink += 'windows'
      } else {
        appLink += 'osx'
      }
      return (
        <div>
          <Helmet
            title={intl.formatMessage({ id: 'title' })}
            meta={[
              {
                name: 'description',
                content: intl.formatMessage({ id: 'welcome' }),
              },
              {
                name: 'keywords',
                content:
                  'gatsby, i18n, react-intl, multi language, localization',
              },
            ]}
          />
          <div className={styles.navigation}>
            <Link className={styles.logo} to={homeLink}>
              <img alt="Telavox Logo" src={logo} />
            </Link>
            <div className={styles.languages}>
              <Language locale={locale} location={location} />
            </div>
            <nav>
              <Link getProps={isPartiallyActive} to={appLink}>
                <FormattedMessage id="apps" />
              </Link>
              <Link getProps={isPartiallyActive} to={conferenceLink}>
                <FormattedMessage id="menu_conf" />
              </Link>
              <Link getProps={isPartiallyActive} to={calendarLink}>
                <FormattedMessage id="menu_cal" />
              </Link>
              <a
                className={styles.button}
                href="https://admin.joinflow.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FormattedMessage id="sign_in" />
              </a>
            </nav>
          </div>
        </div>
      )
    }
  }
}

export default injectIntl(Header)
